import * as React from 'react';
import University from 'components/landing-page/University';
import { graphql } from 'gatsby';

const UniversityPage = ({}) => {
  return <University/>
};

export default UniversityPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

