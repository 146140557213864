import React, { useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as S from './style';
import { useForm, Controller } from 'react-hook-form';
import { Input } from 'components/UI/Common/LandingPage';
import PhoneInput from '../PhoneInput';
import InputWrapper from './InputWrapper';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Toast } from 'components/UI';
import PropTypes from "prop-types";

const YoutubeEmbed = ({ embedId }) => (
  <div className="video-responsive" style={{
    overflow: 'hidden',
    paddingBottom: '56.25%',
    position: 'relative',
    height: 0
  }}>
    <iframe
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
      style={{
        left: 0,
        top: 0,
        height: '100%',
        width: '100%',
        position: 'absolute'
      }}
    />
  </div>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};


const ContactForm = ({ content, id }) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, dirtyFields },
  } = useForm({ mode: 'onSubmit' });
  // const [sumbitting, setSumbitting] = useState(false);

  const { t } = useTranslation();

  let inputs = [
    // { name: 'first_name', type: 'text', label: content.first_name },
    // { name: 'last_name', type: 'text', label: content.last_name },
    // { name: 'email', type: 'email', label: content.email },
    // { name: 'phone', type: 'phone', label: content.phone },
    // {
    //   name: 'select_employees',
    //   type: 'select',
    //   label: content.select_employees,
    //   placeholder: t('Please select'),
    //   selectItems: [
    //     { value: '1', label: '1' },
    //     { value: '2-10', label: '2-10' },
    //     { value: '11-100', label: '11-100' },
    //     { value: '101-1000', label: '101-1000' },
    //     { value: '1000+', label: '1000+' },
    //   ],
    // },
    // { name: 'message', type: 'textarea', label: content.message },
  ];
  const [submitting, setSubmitting] = useState(false);

  function onSubmit(data) {
    // setSubmitting(true);
    // axios.post('https://api.theos.ai/v1/contact/save/', { name: `${data.first_name} ${data.last_name}`, email: data.email, message: `I want to contact the sales team, my phone number is +${data.phone}, we have ${data.select_employees} employees. Content: ${data.message}` })
    //   .then((response) => {
    //     setSubmitting(false);
    //     toast.success(t('Contact success'));
    //     reset();
    //   })
    //   .catch((error) => {
    //     toast.error(t('Contact error'));
    //     setSubmitting(false);
    //   });
    //open https://buy.stripe.com/bIYcNzdNZ3bx4bm14g
    window.open('https://buy.stripe.com/bIYcNzdNZ3bx4bm14g', '_blank');
  }

  return (
    <S.ContactContainer id={id}>
      <Toast />
      <S.Title>{content.title}</S.Title>
      <S.Description>{content.description}</S.Description>
      <YoutubeEmbed embedId="KT0tUrY6JxM" />
      <S.Features>
        {content.features.map((feature, index) => (
          <S.Feature>• {feature}</S.Feature>
        ))}
      </S.Features>
      <S.FormGroup autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
        {inputs.map((input, index) => {
          switch (input.type) {
            case 'phone':
              return (
                <InputWrapper
                  label={input.label}
                  key={index}
                >
                  <Controller
                    control={control}
                    name={input.name}
                    render={({ field: { ref, ...field } }) => (
                      <PhoneInput
                        {...field}
                        //error={errors.phone}
                        inputExtraProps={{
                          ref,
                          required: true,
                          autoFocus: true,
                        }}
                        enableSearch
                        country={'us'}
                      />
                    )}
                  />
                </InputWrapper>
              );

            case 'select':
              return (
                <InputWrapper label={input.label} key={index}>
                  <Controller
                    defaultValue=''
                    render={({ field }) => (
                      <S.Select {...field} label={t('Please Select')}>
                        {input.selectItems.map((item, index) => (
                          <S.SelectItem value={item.value} key={index}>
                            {item.label}
                          </S.SelectItem>
                        ))}
                      </S.Select>
                    )}
                    name='select_employees'
                    control={control}
                  />
                </InputWrapper>
              );

            default:
              return (
                <Input
                  name={input.name}
                  register={register}
                  type={input.type}
                  label={input.label}
                  error={errors[input.name]}
                  isDirty={dirtyFields[input.name]}
                  key={index}
                />
              );

          }
        })}
        <S.ButtonContainer>
          <S.ContactButton
            name='submit'
            type='submit'
            loading={submitting ? 1 : undefined}
          >
            {t('Join Now')}
          </S.ContactButton>
        </S.ButtonContainer>
      </S.FormGroup>
    </S.ContactContainer>
  );
};

export default ContactForm;
